import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function MenusList({ customerMadeMenus }) {
  if (!customerMadeMenus || customerMadeMenus.length === 0) {
    return (
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography variant="body1">No menus found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography className="responsive_fontsize24" variant="h6" sx={{ textAlign: "center", mb: 2 }}>
        Customer Made Menus
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                S.N
              </TableCell>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Menu Name
              </TableCell>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Items
              </TableCell>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                {" "}
                Total Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerMadeMenus.map((menu, index) => (
              <TableRow key={menu.menu_id}>
                <TableCell className="responsive_fontsize18" align="center">{index + 1}</TableCell>
                <TableCell className="responsive_fontsize18" align="center">{menu.menu_name || "N/A"}</TableCell>
                <TableCell className="responsive_fontsize18" align="center">
                  {menu.menu_items && Array.isArray(menu.menu_items)
                    ? menu.menu_items
                        .map(
                          (itemObj) =>
                            `${itemObj.item_name} (Quantity: ${itemObj.item_quantity})`
                        )
                        .join(", ")
                    : "N/A"}
                </TableCell>
                <TableCell className="responsive_fontsize18" align="center"> {menu.total_price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MenusList;
