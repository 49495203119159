import React, { useState } from "react";
import { Box, Button, Avatar, Typography } from "@mui/material";
import packageIcon from "../../assets/packageIcon.png";
import cookingIcon from "../../assets/cookingIcon.png";
import deliverIcon from "../../assets/deliverIcon.png";
import deliveredIcon from "../../assets/deliveredIcon.png";

function TrackSectionOrderDetails({ orders }) {
  const [trackingId, setTrackingId] = useState("");
  const [trackStatus, setTrackStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Function to handle the tracking ID submission
  const handleTrackOrder = () => {
    const order = orders.find((order) => order.tracking_id === trackingId);
  
    if (order) {
      // Normalize the track status and remove extra quotes if present
      const normalizedTrackStatus = order.track_status.trim().replace(/^"|"$/g, '').toLowerCase();
      setTrackStatus(normalizedTrackStatus);
      setErrorMessage("");
      console.log("Track Status (normalized):", normalizedTrackStatus);  // Debug normalized value
    } else {
      setTrackStatus(null);  // Reset trackStatus when order is not found
      setErrorMessage("Tracking ID doesn't match any order.");
    }
  };

  // Function to get styles for icons based on track status
  const getIconStyles = (status) => {
    // Normalize both the trackStatus and the given status to lowercase
    const normalizedStatus = status.toLowerCase();
    const isActive = trackStatus && trackStatus === normalizedStatus; // Check if the status matches trackStatus
  
    // Debug the trackStatus and status
    console.log(`TrackStatus: "${trackStatus}", status: "${normalizedStatus}"`);
    console.log(`TrackStatus type: ${typeof trackStatus}, status type: ${typeof normalizedStatus}`);
    console.log(`IsActive: ${isActive}`);
  
    // Neutral background and subtle hover effects
    return {
      width: { xs: 40, sm: 54 },
      height: { xs: 40, sm: 54 },
      backgroundColor: isActive ? "#EAEAEA" : "#f0f0f0",  // Neutral background for inactive state
      boxShadow: isActive ? "0px 0px 10px 2px #000" : "none", // Apply shadow if active
      border: isActive ? "2px solid rgb(238, 245, 103)" : "2px solid #D3D3D3", // Stronger color if active, neutral border if inactive
      borderRadius: "50%", // Rounded icon corners
      transition: "all 0.3s ease", // Smooth transition for hover and state change
      ...(isActive && {
        "&:hover": {
          transform: "scale(1.1)", // Slightly enlarge on hover
          boxShadow: "0px 0px 15px 3px rgb(238, 245, 103)", // More prominent shadow on hover
        },
      }), // Apply hover styles only if active
    };
  };  

  return (
    <>
      {/* Input for Tracking ID */}
      <form style={{ position: "relative" }}>
        <input
          type="text"
          placeholder="Enter Tracking Id: "
          className="track__order--input"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            backgroundColor: "#000",
            fontSize: { xs: "0.85rem", sm: "1.25rem" },
            textTransform: "capitalize",
            borderRadius: "1.25rem",
            px: { xs: "0.95rem", sm: "1.2rem" },
            color: "#fff",
            height: "100%",
          }}
          onClick={handleTrackOrder}
        >
          Track Order
        </Button>
      </form>

      {/* Error Message */}
      {errorMessage && (
        <Typography color="error" variant="body1" sx={{ marginTop: "1rem" }}>
          {errorMessage}
        </Typography>
      )}
{/* 
      Track Status
      {trackStatus && (
        <Typography
          variant="h6"
          sx={{ marginTop: "1rem", fontWeight: "bold", textAlign: "center" }}
        >
          Track Status: {trackStatus.charAt(0).toUpperCase() + trackStatus.slice(1)}
        </Typography>
      )} */}

      {/* Icons with Conditional Highlight */}
      <Box
        sx={{
          position: "relative",
          mt: "1.5rem",
          height: { xs: "2.5rem", sm: "3.5rem" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "0.1875rem",
            background: "#D9D9D9",
          }}
        ></Box>

        <Box sx={{ position: "absolute", top: "0", left: "0" }}>
          <Avatar
            alt="Cooking"
            src={cookingIcon}
            sx={{...getIconStyles("cooking"),backgroundColor:"transparent",boxShadow:"none"}} 
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "25%",
            transform: "translateX(50%)",
          }}
        >
          <Avatar
            alt="Packing"
            src={packageIcon}
            sx={getIconStyles("packing")}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "25%",
            transform: "translateX(-50%)",
          }}
        >
          <Avatar
            alt="On the way"
            src={deliverIcon}
            sx={getIconStyles("on the way")}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
        >
          <Avatar
            alt="Delivered"
            src={deliveredIcon}
            sx={getIconStyles("delivered")}
          />
        </Box>
      </Box>
    </>
  );
}

export default TrackSectionOrderDetails;
