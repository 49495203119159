// src/OrdersList.jsx
import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function OrdersList({ orders }) {
  if (!orders || orders.length === 0) {
    return (
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography variant="body1">No orders found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography className="responsive_fontsize24" variant="h6" sx={{ textAlign: "center", mb: 2 }}>
        Orders Made
      </Typography>
      <TableContainer component={Paper}
      sx={{
        maxHeight:400,
        overflowY:"auto"
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                S.N
              </TableCell>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Items Name
              </TableCell>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Tracking ID
              </TableCell>
              <TableCell
                className="responsive_fontsize20"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                Total Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow key={order.order_id}>
                <TableCell className="responsive_fontsize18" align="center">{index + 1}</TableCell>
                <TableCell className="responsive_fontsize18" align="center">
                  {order.order_items &&
                  Array.isArray(JSON.parse(order.order_items))
                    ? JSON.parse(order.order_items)
                        .map(
                          (itemObj) =>
                            `${itemObj.name} (Quantity: ${itemObj.quantity})`
                        )
                        .join(", ")
                    : "N/A"}
                </TableCell>{" "}
                <TableCell className="responsive_fontsize18" align="center">{order.tracking_id}</TableCell>
                <TableCell className="responsive_fontsize18" align="center">bbg
                  Rs.{parseFloat(order.total_price).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default OrdersList;
